import { createContext, useContext, useState, useEffect } from 'react';
import { getFacilityIdByType } from '../utils/getFacilityIdByType';
import { facilityTypes } from '../utils/facilityTypes';
import axios from 'axios';
import md5 from 'blueimp-md5';

export const MyPagesContext = createContext({});

export const useMyPagesData = () => useContext(MyPagesContext);

export const MyPagesContextProvider = ({
    children,
    facilityId = '',
    facilityAddressId = '',
    customerFacilities = [],
    customerInvoices = [],
    facilityType = null,
    loggedOutUrl = '',
    error,
    isLoggedInOnMyPages,
}) => {
    let [facilityIdValue, setFacilityId] = useState(facilityId);
    let [facilityAddressIdValue, setFacilityAddressId] =
        useState(facilityAddressId);
    let [facilitiesValue, setFacilities] = useState([]);
    let [customerFacilitiesValue, setCustomerFacilities] =
        useState(customerFacilities);
    let [customerInvoicesValue, setCustomerInvoices] =
        useState(customerInvoices);
    let [facilityTypeValue, setFacilityType] = useState(facilityType);

    const getCustomerFacilities = async () => {
        const facilitiesArray = [];

        const response = await axios.get(`/api/mypages/facilities/`);

        response.data.result.items.forEach((item) => {
            const { streetAddress } = item.address;
            const trimmedAddress = streetAddress
                .replace(/\s{2,}/g, ' ')
                .trim()
                .toUpperCase();
            const id = md5(trimmedAddress);

            const facilityIndex = facilitiesArray.findIndex(
                (facility) => facility.id === id
            );

            const startDate = item.agreementPeriods[0]?.startDate;
            item.type = getFacilityType(item);

            if (facilityIndex === -1) {
                facilitiesArray.push({
                    id: id,
                    address: trimmedAddress,
                    items: [
                        {
                            type: getFacilityType(item),
                            id: item.id,
                            startDate: startDate,
                            entityDetails: item.entityDetails,
                        },
                    ],
                });
            } else {
                facilitiesArray[facilityIndex].items.push({
                    type: getFacilityType(item),
                    id: item.id,
                    startDate: startDate,
                    entityDetails: item.entityDetails,
                });
                facilitiesArray[facilityIndex].items.sort((a, b) => {
                    if (a.type === 'consumption') return -1;
                    if (b.type === 'consumption') return 1;
                    if (a.type === 'production') return -1;
                    if (b.type === 'production') return 1;
                    return 0;
                });
            }
        });

        return {
            customerFacilities: facilitiesArray,
            facilities: response.data.result.items,
        };
    };

    const getCustomerInvoices = async () => {
        const invoicesArray = [];
        const response = await axios.get(`/api/mypages/invoices/`);

        response.data.result.items.forEach((item) => {
            invoicesArray.push({
                paid: item.paid,
            });
        });

        return {
            customerInvoices: invoicesArray,
        };
    };

    const getFacilityType = (item) => {
        if (item?.direction === 'Consumption') {
            return facilityTypes[0];
        }

        if (item?.entityDetails?.districtHeating) {
            return facilityTypes[1];
        }

        if (item?.direction === 'Production') {
            return facilityTypes[2];
        }
    };

    useEffect(() => {
        async function fetchData() {
            if (!isLoggedInOnMyPages) {
                return;
            }

            try {
                const { customerFacilities, facilities } =
                    await getCustomerFacilities();

                const { customerInvoices } = await getCustomerInvoices();

                if (!customerFacilities.length || !facilities.length) {
                    return;
                }

                setCustomerFacilities(customerFacilities);
                setCustomerInvoices(customerInvoices);
                setFacilities(facilities);
                setFacilityAddressId(customerFacilities[0].id);
            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    if (error.response.status === 401 && window.location) {
                        window.location.replace(loggedOutUrl);
                        return;
                    }
                }

                throw error;
            }
        }

        fetchData();
    }, [isLoggedInOnMyPages]);

    useEffect(() => {
        if (!isLoggedInOnMyPages) {
            return;
        }

        const updatedFacility = customerFacilitiesValue.find(
            (facility) => facility.id === facilityAddressIdValue
        );

        const updatedFacilityType = updatedFacility?.items[0].type;
        setFacilityType(updatedFacilityType);

        if (updatedFacility) {
            const updatedFacilityId = getFacilityIdByType(
                updatedFacility.items,
                updatedFacilityType
            );
            setFacilityId(updatedFacilityId);
        }
    }, [facilityAddressIdValue, isLoggedInOnMyPages]);

    useEffect(() => {
        if (!isLoggedInOnMyPages) {
            return;
        }

        const updatedFacility = customerFacilitiesValue.find(
            (facility) => facility.id === facilityAddressIdValue
        );

        if (updatedFacility) {
            const updatedFacilityId = getFacilityIdByType(
                updatedFacility.items,
                facilityTypeValue
            );
            setFacilityId(updatedFacilityId);
        }
    }, [facilityTypeValue, isLoggedInOnMyPages]);

    return (
        <MyPagesContext.Provider
            value={{
                facilityId: [facilityIdValue, setFacilityId],
                facilityAddressId: [
                    facilityAddressIdValue,
                    setFacilityAddressId,
                ],
                customerFacilities: [
                    customerFacilitiesValue,
                    setCustomerFacilities,
                ],
                customerInvoices: [customerInvoicesValue, setCustomerInvoices],
                facilities: [facilitiesValue, setFacilities],
                facilityType: [facilityTypeValue, setFacilityType],
                isLoggedInOnMyPages: !!isLoggedInOnMyPages,
                loggedOutUrl,
                error,
            }}
        >
            {children}
        </MyPagesContext.Provider>
    );
};

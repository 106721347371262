import React from 'react';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import styles from './MyPagesFooter.module.scss';

const MyPagesFooter = ({ contact = {}, social = {} }) => {
    const { t } = useTranslation();

    return (
        <footer className={styles['MyPagesFooter']}>
            <div className={styles['MyPagesFooter__Container']}>
                <div className={styles['MyPagesFooter__Inner']}>
                    <Contact t={t} {...contact} />
                    <Social t={t} {...social} />
                    <div className={styles['MyPagesFooter__Logo']} />
                </div>
            </div>
        </footer>
    );
};

MyPagesFooter.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            links: PropTypes.PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string,
                    label: PropTypes.string,
                    href: PropTypes.string,
                })
            ),
        })
    ),
    social: PropTypes.shape({
        facebookUrl: PropTypes.string,
        instagramUrl: PropTypes.string,
        linkedinUrl: PropTypes.string,
    }),
    contact: PropTypes.shape({
        mail: PropTypes.string,
        phone: PropTypes.string,
        address: PropTypes.string,
        zipcode: PropTypes.string,
        city: PropTypes.string,
    }),
};

const Contact = ({
    mail = '',
    phone = '',
    exchange = '',
    address = '',
    zipcode = '',
    city = '',
    t,
}) => {
    const hasContactInfo =
        !!mail || !!phone || !!address || !!zipcode || !!city;

    if (!hasContactInfo) {
        return;
    }
    
    return (
        <div className={styles['MyPagesFooter__Contact']}>
            <ul className={styles['MyPagesFooter__ContactList']}>
                {!!mail && (
                    <li className={styles['MyPagesFooter__ContactItem']}>
                        <Icon
                            type={'mail'}
                            modifiers={['footer']}
                            dimensions={{ width: '20px', height: '20px' }}
                        />
                        <a
                            className={styles['MyPagesFooter__ContactInfo']}
                            href={`mailto:${mail}`}>
                            {mail}
                        </a>
                    </li>
                )}
                {!!phone && (
                    <li className={styles['MyPagesFooter__ContactItem']}>
                        <Icon
                            type={'phone'}
                            modifiers={['footer']}
                            dimensions={{ width: '20px', height: '20px' }}
                        />
                        {t('Footer.customerService')}:&nbsp;
                        <a
                            className={styles['MyPagesFooter__ContactInfo']}
                            href={`tel:${phone.replace(/\s/g, '')}`}>
                            {phone}
                        </a>
                    </li>
                )}
                {!!exchange && (
                    <li className={styles['MyPagesFooter__ContactItem']}>
                        <Icon
                            type={'phone'}
                            modifiers={['footer', 'blue']}
                            dimensions={{ width: '20px', height: '20px' }}
                        />
                        {t('Footer.exchange')}:&nbsp;
                        <a
                            className={styles['MyPagesFooter__ContactInfo']}
                            href={`tel:${exchange.replace(/\s/g, '')}`}>
                            {exchange}
                        </a>
                    </li>
                )}
            </ul>
            <address>
                <ul className={styles['MyPagesFooter__ContactList']}>
                    {!!address && (
                        <li className={styles['MyPagesFooter__ContactItem']}>
                            {address}
                        </li>
                    )}
                    {!!zipcode && !!city && (
                        <li className={styles['MyPagesFooter__ContactItem']}>
                            {zipcode} {city}
                        </li>
                    )}
                </ul>
            </address>
        </div>
    );
};

const Social = ({
    facebookUrl = '',
    instagramUrl = '',
    linkedinUrl = '',
    t,
}) => {
    const hasSocial = !!facebookUrl || !!instagramUrl || !!linkedinUrl;

    if (!hasSocial) {
        return;
    }
    return (
        <div className={styles['MyPagesFooter__Social']}>
            <div className={styles['MyPagesFooter__SocialItem']}>
                {!!instagramUrl && (
                    <a
                        href={instagramUrl}
                        className={styles['MyPagesFooter__IconLink']}
                        rel="noopener noreferrer">
                        <Icon
                            type={'instagram'}
                            dimensions={{
                                width: '24px',
                                height: '24px',
                            }}
                        />
                        <span className="sr-only">Instagram</span>
                    </a>
                )}
                {!!linkedinUrl && (
                    <a
                        href={linkedinUrl}
                        className={styles['MyPagesFooter__IconLink']}
                        rel="noopener noreferrer">
                        <Icon
                            type={'linkedin'}
                            dimensions={{
                                width: '24px',
                                height: '24px',
                            }}
                        />
                        <span className="sr-only">LinkedIn</span>
                    </a>
                )}
                {!!facebookUrl && (
                    <a
                        href={facebookUrl}
                        className={styles['MyPagesFooter__IconLink']}
                        rel="noopener noreferrer">
                        <Icon
                            type={'facebook'}
                            dimensions={{
                                width: '24px',
                                height: '24px',
                            }}
                        />
                        <span className="sr-only">Facebook</span>
                    </a>
                )}
            </div>
        </div>
    );
};

export default MyPagesFooter;
